// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { DashboardOutlined, LineChartOutlined, FileUnknownOutlined, InboxOutlined, UserOutlined } from '@ant-design/icons';

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const supplierMenuItems = {
  id: 'dashboard',
  title: <FormattedMessage id="dashboard" />,
  type: 'group',
  children: [
    {
      id: 'supplier-profile-page',
      title: 'My Profile',
      type: 'item',
      url: '/profile',
      icon: UserOutlined
    },
    {
      id: 'supplier-overview-page',
      title: 'Overview',
      type: 'item',
      url: '/overview',
      icon: DashboardOutlined
    },
    {
      id: 'supplier-hedges-page',
      title: 'Hedges',
      type: 'item',
      url: '/hedges',
      icon: LineChartOutlined
    },
    {
      id: 'supplier-estimates-page',
      title: 'Estimates',
      type: 'item',
      url: '/estimates',
      icon: FileUnknownOutlined
    },

    {
      id: 'supplier-shipments-page',
      title: 'Shipments',
      type: 'item',
      url: '/shipments',
      icon: InboxOutlined
    }
  ]
};

export default supplierMenuItems;

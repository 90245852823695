// project import
import Routes from 'routes';
import ThemeCustomization from 'themes';
import Locales from 'components/Locales';
// import RTLLayout from 'components/RTLLayout';
import ScrollTop from 'components/ScrollTop';
import Snackbar from 'components/@extended/Snackbar';
import { ConfirmProvider } from 'material-ui-confirm';

// auth provider
import { FirebaseProvider as AuthProvider } from 'contexts/FirebaseContext';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { QueryClient, QueryClientProvider } from 'react-query';
import { SnackbarProvider } from 'notistack';

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false
    }
  }
});

const App = () => (
  <ThemeCustomization>
    <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
      <Locales>
        <ScrollTop>
          <AuthProvider>
            <ConfirmProvider>
              <QueryClientProvider client={queryClient}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <>
                    <Routes />
                    <Snackbar />
                  </>
                </LocalizationProvider>
              </QueryClientProvider>
            </ConfirmProvider>
          </AuthProvider>
        </ScrollTop>
      </Locales>
    </SnackbarProvider>
  </ThemeCustomization>
);

export default App;

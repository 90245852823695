import { Box } from '@mui/material';
import logo from 'assets/images/logo-icon.png';

// material-ui
/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoIconDark from 'assets/images/logo-icon-dark.svg';
 * import logoIcon from 'assets/images/logo-icon.svg';
 *
 */

// ==============================|| LOGO ICON SVG ||============================== //

const LogoIcon = () => {
  return <Box sx={{ width: '100%' }} component="img" src={logo} />;
};

export default LogoIcon;

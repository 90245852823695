export const drawerWidth = 260;

export const FIREBASE_API = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

export const HEDGING_API = {
  baseUrl: process.env.REACT_APP_API_BASE_URL
};

// ==============================|| THEME CONFIG  ||============================== //

const config = {
  defaultPath: '/overview',
  fontFamily: `'Public Sans', sans-serif`,
  i18n: 'en',
  miniDrawer: false,
  container: true,
  mode: 'light',
  presetColor: 'default',
  themeDirection: 'ltr'
};

export default config;

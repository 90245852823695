import PropTypes from 'prop-types';

import { Box } from '@mui/material';

import logo from 'assets/images/logo.png';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const LogoMain = () => {
  return <Box sx={{ width: '100%', p: 3 }} component="img" src={logo} />;
};

LogoMain.propTypes = {
  reverse: PropTypes.bool
};

export default LogoMain;

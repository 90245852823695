import adminMenuItems from './admin-menu-items';
import supplierMenuItems from './supplier-menu-items';

// ==============================|| MENU ITEMS ||============================== //

export const adminMenu = {
  items: [adminMenuItems]
};

export const supplierMenu = {
  items: [supplierMenuItems]
};

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { DashboardOutlined, LineChartOutlined, IdcardOutlined, FileUnknownOutlined, FileDoneOutlined } from '@ant-design/icons';

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const adminMenuItems = {
  id: 'dashboard',
  title: <FormattedMessage id="dashboard" />,
  type: 'group',
  children: [
    {
      id: 'overview-page',
      title: 'Overview',
      type: 'item',
      url: '/overview',
      icon: DashboardOutlined
    },
    {
      id: 'hedges-page',
      title: 'Hedges',
      type: 'item',
      url: '/hedges',
      icon: LineChartOutlined
    },
    {
      id: 'estimates-page',
      title: 'Estimates',
      type: 'item',
      url: '/estimates',
      icon: FileUnknownOutlined
    },
    {
      id: 'settlements-page',
      title: 'Settlements',
      type: 'item',
      url: '/settlements',
      icon: FileDoneOutlined
    },
    {
      id: 'suppliers-page',
      title: 'Suppliers',
      type: 'item',
      url: '/suppliers',
      icon: IdcardOutlined
    }
  ]
};

export default adminMenuItems;

import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/Loadable';
import SupplierGuard from 'utils/route-guard/SupplierGuard';

// render - sample page
const SupplierDashboardPage = Loadable(lazy(() => import('pages/dashboard/supplier-dashboard-page')));
const SupplierHedgesPage = Loadable(lazy(() => import('pages/dashboard/supplier-hedges-page')));
const SupplierEstimatesPage = Loadable(lazy(() => import('pages/dashboard/supplier-estimates-page')));
const SupplierShipmentsPage = Loadable(lazy(() => import('pages/dashboard/supplier-shipments-page')));
const SupplierProfilePage = Loadable(lazy(() => import('pages/dashboard/supplier-profile-page')));

// ==============================|| MAIN ROUTING ||============================== //

const SupplierRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <SupplierGuard>
          <MainLayout />
        </SupplierGuard>
      ),
      children: [
        {
          path: 'overview',
          element: <SupplierDashboardPage />
        },
        {
          path: 'hedges',
          element: <SupplierHedgesPage />
        },
        {
          path: 'estimates',
          element: <SupplierEstimatesPage />
        },
        {
          path: 'shipments',
          element: <SupplierShipmentsPage />
        },
        {
          path: 'profile',
          element: <SupplierProfilePage />
        }
      ]
    }
  ]
};

export default SupplierRoutes;

import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// render - sample page
const DashboardPage = Loadable(lazy(() => import('pages/dashboard/overview-page')));
const HedgesPage = Loadable(lazy(() => import('pages/dashboard/hedges-page')));
const EstimatesPage = Loadable(lazy(() => import('pages/dashboard/estimates-page')));
const SuppliersPage = Loadable(lazy(() => import('pages/dashboard/suppliers-page')));
const SettlementsPage = Loadable(lazy(() => import('pages/dashboard/settlements-page')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'overview',
          element: <DashboardPage />
        },
        {
          path: 'hedges',
          element: <HedgesPage />
        },
        {
          path: 'estimates',
          element: <EstimatesPage />
        },
        {
          path: 'settlements',
          element: <SettlementsPage />
        },
        {
          path: 'suppliers',
          element: <SuppliersPage />
        }
      ]
    }
  ]
};

export default MainRoutes;
